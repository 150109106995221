export const breakpoints = {
  tablet: { em: "35em", px: "560px" },
  desktop: { em: "52em", px: "832px" },
};

export const customColors = {
  darkGold: "#8b6e22",
  lightGold: "#ffffea",
  textBlack: "#424242",
  accentBlue: "#132584",
  backgroundGray: "#f3f1ed",
  backgroundPeach: "#FFF6E9",
  footerGray: "#706968",
  valentinesBg: "#8c5153",
  valentinesButton: "#325655",
};

export default {
  breakpoints: [breakpoints.tablet.em, breakpoints.desktop.em],
  buttons: {
    main: {
      backgroundColor: "darkGold",
      color: "white",
      fontWeight: "bolder",
      fontFamily: "heading",
      borderRadius: 0,
      paddingX: "2rem",
      paddingY: "1rem",
      border: `2px solid ${customColors.darkGold}`,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "transparent",
        color: "darkGold",
      },
    },
    secondaryGold: {
      backgroundColor: "transparent",
      color: "darkGold",
      border: `2px solid ${customColors.darkGold}`,
      borderRadius: 0,
      fontWeight: "bolder",
      fontFamily: "heading",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkGold",
        color: "white",
      },
    },
    secondary: {
      backgroundColor: "transparent",
      color: "accentBlue",
      border: `2px solid black`,
      borderRadius: 0,
      fontWeight: "bold",
      fontFamily: "heading",
      cursor: "pointer",
    },
  },
  colors: {
    ...customColors,
    text: customColors.textBlack,
    background: customColors.backgroundGray,
    primary: customColors.darkGold,
  },
  fonts: {
    body: '"Crimson Text", serif',
    heading: "Raleway, sans-serif",
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 300,
    bold: 700,
  },
  forms: {
    input: {
      fontFamily: "body",
    },
    label: {
      fontFamily: "heading",
      fontWeight: "bolder",
      marginBottom: "0.5rem",
    },
  },
  space: [0, 8, 12, 16, 20, 24, 28, 32],
  styles: {
    a: {
      fontFamily: "heading",
    },
  },
  text: {
    default: {
      color: "textBlack",
      fontStyle: "italic",
      fontSize: 3,
    },
    blueHeading: {
      color: "accentBlue",
      fontWeight: 600,
      fontSize: 5,
    },
  },
};
